export default {
  methods: {
    /** calc mean value of set yield values */
    calcMean(values, prop = 'yield') {
      // calc mean of values
      let accSum = 0;
      const mean = values.reduce((acc, arr) => {
        accSum = acc + arr[prop];
        return accSum;
      }, 0) / values.length;
      return mean;
    },
    /** calc y-axis value from depending of current yield type of calculation */
    calcMedianIndex(mean, yieldVal, crop, type, apiDataCrops, wpCrops) {
      if (!apiDataCrops?.length || !wpCrops?.length) return {};

      let index = Math.max(0, ((mean - yieldVal) / mean));
      if (type === 'percent') {
        index *= 100;
      } else if (type === 'euro') {
        /** find crop ha */
        const ha = Number.parseFloat(apiDataCrops.filter((c) => c.type === crop)[0]?.hectar);
        /** find crop value */
        const eurDt = Number.parseFloat(wpCrops.filter((c) => c.id === crop)[0]?.eur_dt);
        index = index * mean * ha * eurDt;
      }
      return Math.round(index * 100) / 100;
    },
  },
};
