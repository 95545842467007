const businessType = {
  crop: 'crop',
  volksfest: 'volksfest',
  restaurant: 'restaurant',
  event: 'event',
  custom: 'custom',
  hotel: 'hotel',
  vacation: 'vacation',
};
export default businessType;
